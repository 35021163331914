
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'

import ListCourse from '@/components/agency/course/ListCourse.vue'

import courseService from '../../../services/course'

export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined,
    ListCourse,
    ArrowLeftOutlined
  },
  setup () {
    const route = useRoute()
    const showWidget = ref<boolean>(true)
    const courseType = ref<string>('Child')
    const courseId = ref<string>(route.params.id as string)
    const course = ref<{ _id: string; title: string; description: string; image: string; category: object; discount: number; price: number; published: boolean; type: string }>()
    const getParentCourseDetails = async () => {
      const responce = await courseService.getCourseDetails(courseId.value)
      course.value = responce.data
    }
    onMounted(() => {
      console.log('yes')
      getParentCourseDetails()
    })
    return {
      showWidget,
      courseType,
      courseId,
      course
    }
  }
})
